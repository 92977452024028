
import { useVuelidate } from "@vuelidate/core";
import { minLength, email, required } from "@vuelidate/validators";
import { computed, defineComponent, onBeforeMount, reactive, ref } from "vue";

import { fn } from "@/firebaseManager";
import { useRouter } from "vue-router";

import Icon from "@/components/Icon.vue";

export default defineComponent({
  components: {
    Icon,
  },

  setup() {
    const router = useRouter();
    const inputAccessCode = ref("");
    const accessCodeChecked = ref(false);
    const accesCodeValid = ref(false);
    const accessCodeDetails = ref();
    const validatingAccessCode = ref(false);

    const createAccountError = ref(false);
    const createAccountFormActive = ref(false);
    const createAccountForm = reactive({
      displayName: "",
      emailInput: "",
      password: "",
    });
    const emailAddress = computed(() => {
      return `${createAccountForm.emailInput}@${
        accessCodeDetails.value?.emailDomain ?? ""
      }`;
    });
    const creatingAccount = ref(false);

    const courseName = computed(() => {
      if (accessCodeDetails.value) {
        return `${accessCodeDetails.value.courseDetails.code} ${accessCodeDetails.value.courseDetails.name} - ${accessCodeDetails.value.courseDetails.semester} ${accessCodeDetails.value.courseDetails.year}`;
      }
      return "";
    });

    onBeforeMount(() => {
      window.location.replace("https://edu.steamplug.com/activate");
    });

    function checkAccessCode() {
      validatingAccessCode.value = true;
      const accessCodeValidate = fn.httpsCallable("accessCode-validate");

      accessCodeValidate({ accessCode: inputAccessCode.value })
        .then((response) => {
          accessCodeDetails.value = response.data;
          accesCodeValid.value = true;
        })
        .catch(() => {
          accesCodeValid.value = false;
        })
        .finally(() => {
          accessCodeChecked.value = true;
          validatingAccessCode.value = false;
        });
    }

    const customEmail = (value: any) => {
      return email.$validator(
        `${value}@${accessCodeDetails.value.emailDomain}`
      );
    };
    const createAccountFormRules = {
      displayName: { required, minLength: minLength(2) },
      emailInput: { required, customEmail },
      password: { required, minLength: minLength(6) },
    };

    const v$ = useVuelidate(createAccountFormRules, createAccountForm);

    function createAccount() {
      v$.value.$touch();

      if (v$.value.$invalid) {
        return;
      }

      creatingAccount.value = true;

      const createUserAccount = fn.httpsCallable("user-createUserAccount");
      const data = {
        ...createAccountForm,
        email: emailAddress.value,
        accessCodeId: accessCodeDetails.value.id,
      };

      createUserAccount(data)
        .then(() => {
          createAccountForm.displayName = "";
          createAccountForm.emailInput = "";
          createAccountForm.password = "";

          goToRedeemPage();
        })
        .catch((err) => {
          createAccountError.value = err.message;
        })
        .finally(() => {
          creatingAccount.value = false;
        });
    }

    function goToRedeemPage() {
      router.push({
        name: "Redeem",
        params: { accessCodeId: accessCodeDetails.value.id },
      });
    }

    function createAccountCancel() {
      v$.value.$reset();
      createAccountFormActive.value = false;
      createAccountForm.displayName = "";
      createAccountForm.emailInput = "";
      createAccountForm.password = "";
    }

    return {
      inputAccessCode,
      accessCodeChecked,
      accesCodeValid,
      checkAccessCode,
      createAccountError,
      accessCodeDetails,
      createAccount,
      createAccountForm,
      courseName,
      goToRedeemPage,
      createAccountFormActive,
      validatingAccessCode,
      createAccountCancel,
      v$,
      creatingAccount,
    };
  },
});
