<template>
  <div
    class="flex flex-col container mx-auto p-5 max-w-md"
    style="margin-top: 5%"
  >
    <div v-if="!accesCodeValid">
      <h1 class="text-4xl font-semibold pb-3">Redeem access code</h1>

      <form class="space-y-5" @submit.prevent="checkAccessCode">
        <div class="-space-y-px">
          <div>
            <label class="sr-only" for="accessCode">Access code</label>
            <input
              class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              id="accessCode"
              type="text"
              placeholder="Access code"
              v-model="inputAccessCode"
            />
          </div>
        </div>

        <button
          type="submit"
          :disabled="validatingAccessCode"
          :class="{ 'cursor-not-allowed': validatingAccessCode }"
          class="group relative w-full flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <icon
            v-if="validatingAccessCode"
            :name="'arrow-repeat'"
            :class="'animate-spin'"
            :color="'rgb(255, 255, 255)'"
          />
          <span class="ml-2">Redeem</span>
        </button>

        <div class="text-red-500" v-if="accessCodeChecked && !accesCodeValid">
          The access code you entered is either invalid or expired.
        </div>
      </form>
    </div>

    <div v-if="accesCodeValid">
      <p class="text-2xl font-medium">{{ courseName }}</p>

      <div v-if="!createAccountFormActive" class="pt-4">
        <p class="text-base font-semibold text-center mb-4">
          Access Code Valid
        </p>
        <p class="text-justify">
          If you have a STEAMplug account, please sign in to complete your
          activation for this course. If you don't have an account, please
          create one in order to continue.
        </p>

        <div class="mt-8">
          <button
            @click="goToRedeemPage"
            class="w-full py-2 px-4 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 border border-transparent rounded-md focus:outline-none"
          >
            Sign in
          </button>

          <div class="my-6 flex flex-row items-center justify-center">
            <span class="border flex-grow"></span>
            <span class="mx-4 text-xs text-gray-500 uppercase">Or</span>
            <span class="border flex-grow"></span>
          </div>

          <button
            @click="createAccountFormActive = true"
            class="w-full py-2 px-4 text-sm font-medium text-blue-600 bg-white hover:bg-blue-50 border border-blue-500 rounded-md focus:outline-none"
          >
            Create Account
          </button>
        </div>
      </div>

      <div v-else class="pt-4">
        <form class="space-y-5" @submit.prevent="createAccount">
          <div class="space-y-2">
            <div class="space-y-1">
              <label class="text-sm" for="displayName">Full name</label>
              <input
                class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                id="displayName"
                type="text"
                placeholder="Full name"
                v-model="v$.displayName.$model"
              />

              <div
                class="text-xs text-red-500"
                v-for="(error, index) of v$.displayName.$errors"
                :key="index"
              >
                <p>{{ error.$message }}</p>
              </div>

              <div class="text-xs text-gray-400">
                <p>Enter your full name as per your stundet ID.</p>
              </div>
            </div>

            <div class="space-y-1">
              <label class="text-sm" for="email">Email</label>
              <div class="flex flex-row">
                <div class="w-full">
                  <input
                    class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-r-none rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    id="email"
                    type="text"
                    placeholder="Email"
                    v-model="v$.emailInput.$model"
                  />
                </div>
                <div
                  class="sm:text-sm py-2 px-2 border-gray-300 text-gray-900 rounded-l-none rounded-md bg-gray-300"
                >
                  @{{ accessCodeDetails.emailDomain }}
                </div>
              </div>

              <div
                class="text-xs text-red-500"
                v-for="(error, index) of v$.emailInput.$errors"
                :key="index"
              >
                <p>{{ error.$message }}</p>
              </div>

              <div class="text-xs text-gray-400">
                <p>Use your institutional email address.</p>
              </div>
            </div>

            <div class="space-y-1">
              <label class="text-sm" for="password">Password</label>
              <input
                class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                id="password"
                type="password"
                placeholder="Password"
                v-model="v$.password.$model"
              />

              <div
                class="text-xs text-red-500"
                v-for="(error, index) of v$.password.$errors"
                :key="index"
              >
                <p>{{ error.$message }}</p>
              </div>
            </div>
          </div>

          <p class="text-sm text-gray-600">
            By clicking Create Account, you agree to our
            <a href="/terms" target="_blank" class="text-blue-600">
              Terms of Service
            </a>
            and
            <a href="/privacy" target="_blank" class="text-blue-600"
              >Privacy Policy</a
            >.
          </p>

          <button
            type="submit"
            class="group relative w-full flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            :disabled="creatingAccount"
            :class="{ 'cursor-not-allowed': creatingAccount }"
          >
            <icon
              v-if="creatingAccount"
              :name="'arrow-repeat'"
              :class="'animate-spin'"
              :color="'rgb(255, 255, 255)'"
            />
            <span class="ml-2">Create Account</span>
          </button>

          <div class="space-y-2">
            <hr class="mt-2" />
            <p class="text-sm font-light text-gray-600 text-center">
              Already have an account?
              <a
                class="text-blue-600 cursor-pointer"
                @click="createAccountCancel"
              >
                Sign in
              </a>
            </p>
          </div>

          <div class="text-red-500" v-if="createAccountError">
            {{ createAccountError }}
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { minLength, email, required } from "@vuelidate/validators";
import { computed, defineComponent, onBeforeMount, reactive, ref } from "vue";

import { fn } from "@/firebaseManager";
import { useRouter } from "vue-router";

import Icon from "@/components/Icon.vue";

export default defineComponent({
  components: {
    Icon,
  },

  setup() {
    const router = useRouter();
    const inputAccessCode = ref("");
    const accessCodeChecked = ref(false);
    const accesCodeValid = ref(false);
    const accessCodeDetails = ref();
    const validatingAccessCode = ref(false);

    const createAccountError = ref(false);
    const createAccountFormActive = ref(false);
    const createAccountForm = reactive({
      displayName: "",
      emailInput: "",
      password: "",
    });
    const emailAddress = computed(() => {
      return `${createAccountForm.emailInput}@${
        accessCodeDetails.value?.emailDomain ?? ""
      }`;
    });
    const creatingAccount = ref(false);

    const courseName = computed(() => {
      if (accessCodeDetails.value) {
        return `${accessCodeDetails.value.courseDetails.code} ${accessCodeDetails.value.courseDetails.name} - ${accessCodeDetails.value.courseDetails.semester} ${accessCodeDetails.value.courseDetails.year}`;
      }
      return "";
    });

    onBeforeMount(() => {
      window.location.replace("https://edu.steamplug.com/activate");
    });

    function checkAccessCode() {
      validatingAccessCode.value = true;
      const accessCodeValidate = fn.httpsCallable("accessCode-validate");

      accessCodeValidate({ accessCode: inputAccessCode.value })
        .then((response) => {
          accessCodeDetails.value = response.data;
          accesCodeValid.value = true;
        })
        .catch(() => {
          accesCodeValid.value = false;
        })
        .finally(() => {
          accessCodeChecked.value = true;
          validatingAccessCode.value = false;
        });
    }

    const customEmail = (value: any) => {
      return email.$validator(
        `${value}@${accessCodeDetails.value.emailDomain}`
      );
    };
    const createAccountFormRules = {
      displayName: { required, minLength: minLength(2) },
      emailInput: { required, customEmail },
      password: { required, minLength: minLength(6) },
    };

    const v$ = useVuelidate(createAccountFormRules, createAccountForm);

    function createAccount() {
      v$.value.$touch();

      if (v$.value.$invalid) {
        return;
      }

      creatingAccount.value = true;

      const createUserAccount = fn.httpsCallable("user-createUserAccount");
      const data = {
        ...createAccountForm,
        email: emailAddress.value,
        accessCodeId: accessCodeDetails.value.id,
      };

      createUserAccount(data)
        .then(() => {
          createAccountForm.displayName = "";
          createAccountForm.emailInput = "";
          createAccountForm.password = "";

          goToRedeemPage();
        })
        .catch((err) => {
          createAccountError.value = err.message;
        })
        .finally(() => {
          creatingAccount.value = false;
        });
    }

    function goToRedeemPage() {
      router.push({
        name: "Redeem",
        params: { accessCodeId: accessCodeDetails.value.id },
      });
    }

    function createAccountCancel() {
      v$.value.$reset();
      createAccountFormActive.value = false;
      createAccountForm.displayName = "";
      createAccountForm.emailInput = "";
      createAccountForm.password = "";
    }

    return {
      inputAccessCode,
      accessCodeChecked,
      accesCodeValid,
      checkAccessCode,
      createAccountError,
      accessCodeDetails,
      createAccount,
      createAccountForm,
      courseName,
      goToRedeemPage,
      createAccountFormActive,
      validatingAccessCode,
      createAccountCancel,
      v$,
      creatingAccount,
    };
  },
});
</script>
